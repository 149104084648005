<template>
  <ul v-if="story" class="flex gap-2 self-center items-center m-0 h-full">
    <img
      :src="`/lang_icon_${locale}.png`"
      class="flag cursor-pointer"
      :alt="`${locale === 'de' ? 'Aktuelle Sprache: Deutsch' : 'Current language: English'}`"
      @click="toggleFlags = !toggleFlags"
    />

    <template v-for="lang in availableLocales" :key="lang">
      <v-slide-y-reverse-transition>
        <li v-show="toggleFlags && lang !== locale" class="">
          <NuxtLink
            :to="switchLocalePath(lang)"
            @click.stop="
              emit('change', lang);
              toggleFlags = false;
            "
          >
            <img
              :src="`/lang_icon_${lang}.png`"
              :alt="`${
                lang === 'de'
                  ? `Zur deutschen Version von ${story.name}`
                  : `To english version of ${story.name}`
              }`"
              class="flag"
            />
          </NuxtLink>
        </li>
      </v-slide-y-reverse-transition>
    </template>
  </ul>
</template>

<script setup>
const { $store } = useNuxtApp();
const { locale, locales } = useI18n();
const { findLinksWithId, forceTrailingSlash } = useUtils();
const emit = defineEmits(["change"]);

const availableLocales = computed(() => {
  return locales.value
    .filter((i) => i.code !== locale.value)
    .map((locale) => locale.code);
});

const story = computed(() => $store.getters.getStory);

const switchLocalePath = (locale) => {
  let path = null;

  let translation = story.value?.translated_slugs?.find(
    (translation) => translation.lang === locale,
  );

  // If no translation is found, we try to find the parent story
  if (translation && !translation.name) {
    path = findLinksWithId(story.value.parent_id)?.slug;
  } else if (!translation) {
    translation = {
      lang: locale,
      path: story.value?.default_full_slug,
    };
  }

  path = path || translation.path;

  return forceTrailingSlash(
    `/${translation.lang !== "de" ? `${translation.lang}/` : ""}${path !== "home" ? path : ""}`,
  );
};

const toggleFlags = ref(false);
</script>

<style scoped>
.flag {
  height: 22px;
  width: 22px;
  display: inline-block;
  border-radius: 100%;
  filter: grayscale(20%);
  opacity: 0.8;
  transition: all 200ms linear;

  &:hover {
    opacity: 1;
    filter: grayscale(0%);
  }
}
ul {
  margin: 0;
}
</style>
