<script setup>
import "@mdi/font/css/materialdesignicons.min.css";

// const darkMode = useDarkLightSwitch()
const darkMode = true;
</script>

<template>
  <Header />
  <main
    :class="{
      'theme-light': !darkMode,
      'theme-dark': darkMode,
    }"
  >
    <slot />
  </main>
  <Footer />
</template>

<style>
h3 {
  color: var(--1-main-colors--white);
}
.pre-h2 {
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  color: rgb(255, 255, 255) !important;
}
p {
  text-wrap: balance;
}
button,
.w-tab-link,
.clickable {
  cursor: pointer;
  transition: color 0.35s;
}
.white-logo {
  filter: invert(1) brightness(0) invert(1);
}
.feature-bento-box.clickable,
.follow-box {
  transition: all 0.4s;

  &:hover {
    box-shadow: 0 0 0 7px var(--2-other--link-bg-grey);
    color: #fff;
    background-color: #000;

    .follow-box-icon-wrap,
    .clickable-hover-effect {
      opacity: 1;

      &.hover-effect-soft {
        opacity: 1;
      }
    }
  }

  .follow-box-icon-wrap,
  .clickable-hover-effect {
    opacity: 0.6;
    transition: all 0.4s;

    &.hover-effect-soft {
      opacity: 0.85;
    }
  }
}

.v-expansion-panel {
  .v-expansion-panel-title {
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0);
    overflow: hidden;
  }

  &.v-expansion-panel--active {
  }

  border-bottom: 1px solid var(--2-other--light-grey);
  color: var(--1-main-colors--text-color);
}

@media screen and (min-width: 601px) {
  .pre-h2 {
    font-size: larger;
  }
  .card-wrapper .flex-wrap .text-wrapper {
    padding-right: 2em;
  }
}
</style>
