<script setup>
const props = defineProps({
  items: Array,
  parent: Object,
  grid: Boolean,
  withOverview: Boolean,
  allStories: {
    type: Array,
    default: () => [],
  },
});

const analyticsStoryId = 522674377;
const consultingStoryId = 534047196;

const isHero = (blok) =>
  blok.id === analyticsStoryId || blok.id === consultingStoryId;

function haveIcons(items) {
  return items.some((item) => item?._blok?.content?.nav_icon_class);
}

const countElementsIn3rdCol = props.items.filter(
  (item) => !isHero(item),
).length;

const isChallengeRootPage = (blok) => {
  return blok.link.id === "d442c920-91e3-4b22-82ec-37c1a536ea84";
};
</script>
<template>
  <nav
    id="w-dropdown-list-0"
    class="nav-small-dropdown-list"
    :class="{ 'w-100 large': grid }"
  >
    <div
      class="nav-dropdown-list-content nav-dropdown-small-list-content"
      style="
        /* transform: translate3d(0px, -10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */ /* transform-style: preserve-3d; */ /* opacity: 0; */
      "
    >
      <slot>
        <div
          class="small-menu"
          :class="{ 'd-grid': grid, challenges: isChallengeRootPage(parent) }"
        >
          <MyLink
            v-if="parent && !parent?.sub_items?.length && withOverview"
            :link="{ linktype: 'story', url: parent.full_path }"
            class="small-dropdown-link w-inline-block"
          >
            <DropDownNavLinkText
              v-bind="{
                iconClass: haveIcons(items) ? 'mdi-view-dashboard' : '',
                name: $t('default.header.nav.overview.label'),
                subtext: parent._blok.content.metatags.title,
              }"
            />
          </MyLink>

          <!-- <div class="small-nav-dropdown-heading">Sales pages</div> -->
          <div
            v-for="blok in items"
            :key="blok._uid"
            class="nav-link-wrap"
            :class="{ hero: isHero(blok) }"
          >
            <div v-if="blok.navTitle" class="small-nav-dropdown-heading">
              {{ blok.navTitle }}
            </div>
            <MyLink
              v-else
              :link="{ linktype: 'story', url: blok.full_path }"
              class="small-dropdown-link w-inline-block"
            >
              <div
                v-if="isHero(blok)"
                class="hero-wrap feature-bento-box clickable h-100"
              >
                <TeaserContent
                  :headline="blok.name"
                  :teaser="blok?._blok?.content?.nav_subtext"
                  :image="blok?._blok?.content?.nav_icon"
                  image-fit="contain"
                  image-position="center"
                />
              </div>
              <DropDownNavLinkText
                v-else
                v-bind="{
                  iconClass: blok?._blok?.content?.nav_icon_class,
                  name: blok.name,
                  subtext: blok?._blok?.content?.nav_subtext,
                }"
              />
            </MyLink>
          </div>
        </div>
      </slot>

      <div v-if="false & grid" class="nav-dropdown-footer">
        <div class="content">
          <div v-if="false">
            © - with 🤍 from
            <a
              href="https://azwedo.com?ref=template"
              target="_blank"
              class="wilte-link"
              tabindex="0"
              >Azwedo</a
            >
            &amp;&nbsp;<a
              href="https://wedoflow.com?ref=template"
              target="_blank"
              class="wilte-link"
              tabindex="0"
              >Wedoflow</a
            >
          </div>
        </div>
        <div class="content"><div>Free Figma file</div></div>
      </div>
      <div v-if="grid" class="nav-dropdown-gradient">
        <div class="nav-gradient-holder">
          <div class="nav-gradient-box"></div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
.nav-small-dropdown-list {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 20px;

  @media screen and (min-width: 1000px) {
    position: absolute;
    top: 62px;
    margin-bottom: 0px;
  }

  &.w-100 {
    left: 0;
  }

  &.large {
    .nav-dropdown-list-content {
      padding: 1em 3em;
    }
  }
}

.nav-dropdown-list-content {
  z-index: 1;
  grid-column-gap: 0em;
  grid-row-gap: 0em;
  color: #fff;
  background-color: #000;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding: 1em 1em;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.nav-small-dropdown-list .nav-link-wrap {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.small-menu {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;

  &:not(.d-grid) {
    min-width: 200px;
    max-width: 900px;
  }

  @media screen and (min-width: 1000px) {
    &.challenges {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Definiert drei Spalten */
      grid-auto-rows: minmax(
        50px,
        auto
      ); /* Definiert die minimale und maximale Höhe der Zeilen */

      grid-column-gap: 3em;
    }
  }

  &.d-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Definiert drei Spalten */
    grid-auto-rows: minmax(
      50px,
      auto
    ); /* Definiert die minimale und maximale Höhe der Zeilen */

    grid-column-gap: 3em;

    .nav-link-wrap {
      grid-auto-columns: auto;

      &.hero {
        grid-row: span v-bind(countElementsIn3rdCol); /* Erstreckt sich über drei Zeilen */
      }
      &:not(.hero) {
        grid-column: 3;
      }
    }
  }
}
</style>
<style lang="scss">
.small-nav-link-subtext {
  font-size: 13px;
  opacity: 0.6;
}
.nav-link-wrap {
  padding: 5px 0;

  a {
    &:hover,
    &.router-link-active {
      .label {
        color: #a2027b;
      }
    }
  }
}
</style>
